import React from 'react'
import { Link, graphql } from 'gatsby';
import { Card, Layout, SEO, Spotlight, Testimonial } from '../../../components';

export const query = graphql`
  query {
    glazing: file(absolutePath: { regex: "/window.jpg/" }) { childImageSharp { fluid(maxHeight: 400, maxWidth: 900, cropFocus: CENTER) { ...GatsbyImageSharpFluid_withWebp }}},
    glass: file(absolutePath: { regex: "/glass.jpg/" }) { childImageSharp { fluid(maxHeight: 190, maxWidth: 680, cropFocus: SOUTH) { ...GatsbyImageSharpFluid_withWebp }}},
    doors: file(absolutePath: { regex: "/doors.jpg/" }) { childImageSharp { fluid(maxHeight: 190, maxWidth: 680, fit: OUTSIDE) { ...GatsbyImageSharpFluid_withWebp }}},
    doubleGlazing: file(absolutePath: { regex: "/double-glazing.jpg/" }) { childImageSharp { fluid(maxHeight: 190, maxWidth: 680, fit: OUTSIDE) { ...GatsbyImageSharpFluid_withWebp }}},
    repairs: file(absolutePath: { regex: "/repairs.jpg/" }) { childImageSharp { fluid(maxHeight: 190, maxWidth: 680, fit: OUTSIDE) { ...GatsbyImageSharpFluid_withWebp }}},
    upvc: file(absolutePath: { regex: "/upvc.jpg/" }) { childImageSharp { fluid(maxHeight: 190, maxWidth: 680, fit: OUTSIDE) { ...GatsbyImageSharpFluid_withWebp }}},
    worktops: file(absolutePath: { regex: "/worktops.jpg/" }) { childImageSharp { fluid(maxHeight: 190, maxWidth: 680, cropFocus: CENTER) { ...GatsbyImageSharpFluid_withWebp }}},
    splashback: file(absolutePath: { regex: "/splashback.jpg/" }) { childImageSharp { fluid(maxHeight: 190, maxWidth: 680, fit: OUTSIDE) { ...GatsbyImageSharpFluid_withWebp }}},
    mirror: file(absolutePath: { regex: "/mirror.jpg/" }) { childImageSharp { fluid(maxHeight: 190, maxWidth: 680, fit: OUTSIDE) { ...GatsbyImageSharpFluid_withWebp }}},
  }
`

const structuredData = {
  "@context": "https://schema.org",
  "@type": "BreadcrumbList",
  "itemListElement": [{
    "@type": "ListItem",
    "position": 1,
    "name": "Services",
    "item": "https://gp-maintenance.co.uk/services"
  },{
    "@type": "ListItem",
    "position": 2,
    "name": "Glazing Services",
  }]
}

const GlazingServices = ({data}) => (
  <Layout headerBreakpoint={170}>
    <SEO title="Glazing Services" structuredData={structuredData} />

    <Spotlight img={data.glazing}/>

    <section>
      <h1>Glazing Services</h1>
    </section>

    <section variant="grey">
      <div>
        <div style={{overflow: 'hidden', margin: '-20px'}}>
          <Card image={data.doors}>
            <h3>Doors</h3>
            
            <p>We specialise in the installation of many different types of doors. Not only does a door need to look good, it needs to be energy efficient and secure.</p>

            <Link to="/services/glazing-services/doors" className="button primary">Find Out More</Link>
          </Card>

          <Card image={data.doubleGlazing}>
            <h3>Double Glazing</h3>
            
            <p>Double Glazing can really transform your home or office. Not only does it bring energy effeciency, but the benefit of additional security over Single Glazing.</p>

            <Link to="/services/glazing-services/double-glazing" className="button primary">Find Out More</Link>
          </Card>

          <Card image={data.mirror}>
            <h3>Mirrors</h3>
            
            <p>Whether it's for your home, workplace, showroom or shop, we can create and install custom mirrors to suit your bespoke requirements.</p>

            <Link to="/services/glazing-services/mirrors" className="button primary">Find Out More</Link>
          </Card>

          <Card image={data.repairs}>
            <h3>Repairs</h3>
            
            <p>
              Sometimes ‘new’ isn’t what is required as what you already have can be repaired. We won’t automatically offer you new if it’s possible that something can be successfully repaired.
            </p>

            <Link to="/services/glazing-services/repairs" className="button primary">Find Out More</Link>
          </Card>

          <Card image={data.splashback}>
            <h3>Splashbacks</h3>
            
            <p>In the kitchen, it's inveitable that something is going to splash up against that nicely painted wall. With a custom Splashback, you'll be able to keep that paint crystal clean!</p>

            <Link to="/services/glazing-services/splashbacks" className="button primary">Find Out More</Link>
          </Card>
        </div>
      </div>
    </section>

    <Testimonial />
  </Layout>
)

export default GlazingServices
